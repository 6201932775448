
import "./App.css";
import styled from "styled-components";
import React from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Button, Collection, Flex, Grid, IconStar, Link, Loader, Image, Menu, MenuItem, SelectField, TabItem, Tabs, Text, View } from "@aws-amplify/ui-react";
import { FiLogOut } from "react-icons/fi";
import appSettings from "./app-exports";
import VirtualClothingView from "./VirtualClothingView"

const guidPattern = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;

export const GltfContext = React.createContext();

const MainGrid = styled(Grid)`
    height: 100vh;
    width: 100vw;
`;

const GarmentView = styled(View)`
    background-color: #F0F0F0;
    margin: 8px;
`;

function isValidUUID(uuid) {
    return guidPattern.test(uuid);
}

class App extends React.Component {

    constructor(props) {
        console.log("App construct");

        super(props);

        this.state = {
            isProcessing: false,
            isGltfLoading: false,
            scans: {},
            selectedTopGarment: null,
            selectedBottomGarment: null,
            rvTShirtList: [],
            rvKariyushiWearList: [],
            rvNShima1List: [],
            rvCinoList: [],
            gltf: null,
        };

        console.log(appSettings.host);
        this.host = appSettings.host;
        this.mode = appSettings.mode;
        this.signOut = props.signOut;
        this.user = props.user;
        this.token = this.user?.signInUserSession?.accessToken?.jwtToken;
    }

    componentDidMount() {
        this.refreshScans();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.signOut = this.props.signOut;
        this.user = this.props.user;
        this.token = this.user?.signInUserSession?.accessToken?.jwtToken;
    }

    refreshScans = () => {
        this.setState({
            isProcessing: true,
            isGltfLoading: false,
            scans: {},
            selectedTopGarment: null,
            selectedBottomGarment: null,
            rvTShirtList: [],
            rvKariyushiWearList: [],
            rvNShima1List: [],
            rvCinoList: [],
            currentScanId: null,
            gltf: null,
        });

        fetch(appSettings.api_host + "/users/me/scans?host=" + this.host, { headers: new Headers({ "Authorization": "Bearer " + this.token }) })
            .then(response => response.json())
            .then(data => {
                let maxScanId = null;
                let maxDateValue = 0;
                let scanDict = {};

                for (let scanId in data) {
                    if (isValidUUID(scanId)) {
                        let dateValue = Date.parse(data[scanId].scannedAt);
                        let dateString = (new Date(dateValue)).toLocaleString();

                        if (dateValue > maxDateValue) {
                            maxDateValue = dateValue;
                            maxScanId = scanId;
                        }

                        scanDict[scanId] = dateString;
                    }
                }

                this.setState({
                    isProcessing: false,
                    isGltfLoading: false,
                    scans: scanDict,
                    currentScanId: null,
                });
                this.setScanId(maxScanId);
            })
            .catch(() => {
                this.setState({
                    isProcessing: false,
                    scans: {},
                    currentScanId: null,
                });
            });
    }

    setScanId = (value) => {
        const rvTShirtList = [
            { key: "rv:t-shirt:S", label: "S" },
            { key: "rv:t-shirt:M", label: "M" },
            { key: "rv:t-shirt:L", label: "L" },
            { key: "rv:t-shirt:XL", label: "XL" },
        ];
        const rvKariyushiWearList = [
            { key: "rv:kariyushi-wear-open:S", label: "S" },
            { key: "rv:kariyushi-wear-open:M", label: "M" },
            { key: "rv:kariyushi-wear-open:L", label: "L" },
            { key: "rv:kariyushi-wear-open:XL", label: "XL" },
        ];
        const rvNShima1List = [
            { key: "rv:n-shima-1:26", label: "26" },
            //{ key: "rv:n-shima-1:27", label: "27" },
            { key: "rv:n-shima-1:28", label: "28" },
            //{ key: "rv:n-shima-1:29", label: "29" },
            { key: "rv:n-shima-1:30", label: "30" },
            //{ key: "rv:n-shima-1:31", label: "31" },
            { key: "rv:n-shima-1:32", label: "32" },
            //{ key: "rv:n-shima-1:33", label: "33" },
            { key: "rv:n-shima-1:34", label: "34" },
            { key: "rv:n-shima-1:36", label: "36" },
        ];
        const rvCinoList = [
            { key: "rv:cino:XS", label: "XS" },
            { key: "rv:cino:S", label: "S" },
            { key: "rv:cino:M", label: "M" },
            { key: "rv:cino:L", label: "L" },
        ];

        this.setState({ isProcessing: true })
        // then の連鎖を排除
        fetch(appSettings.api_host + "/scans/" + value + "/recommended_size?host=" + this.host + "&garment=rv:t-shirt", { headers: new Headers({ "Authorization": "Bearer " + this.token }) })
            .then(response => response.json())
            .then(json => {
                for (var i = 0; i < rvTShirtList.length; ++i)
                    rvTShirtList[i].recommended = rvTShirtList[i].label === json["size"];
            }).then(() =>
            {
                fetch(appSettings.api_host + "/scans/" + value + "/recommended_size?host=" + this.host + "&garment=rv:kariyushi-wear-open", { headers: new Headers({ "Authorization": "Bearer " + this.token }) })
                .then(response => response.json())
                .then(json => {
                    for (var i = 0; i < rvKariyushiWearList.length; ++i)
                        rvKariyushiWearList[i].recommended = rvKariyushiWearList[i].label === json["size"];
                }).then(() =>
                {
                    fetch(appSettings.api_host + "/scans/" + value + "/recommended_size?host=" + this.host + "&garment=rv:n-shima-1", { headers: new Headers({ "Authorization": "Bearer " + this.token }) })
                    .then(response => response.json())
                    .then(json => {
                        for (var i = 0; i < rvNShima1List.length; ++i)
                            rvNShima1List[i].recommended = rvNShima1List[i].label === json["size"];
                    }).then(() =>
                    {
                        fetch(appSettings.api_host + "/scans/" + value + "/recommended_size?host=" + this.host + "&garment=rv:cino", { headers: new Headers({ "Authorization": "Bearer " + this.token }) })
                        .then(response => response.json())
                        .then(json => {
                            for (var i = 0; i < rvCinoList.length; ++i)
                                rvCinoList[i].recommended = rvCinoList[i].label === json["size"];
                        }).then(() =>
                        {
                            this.setState({ isProcessing: false, currentScanId: value, rvTShirtList: rvTShirtList, rvKariyushiWearList: rvKariyushiWearList, rvNShima1List: rvNShima1List, rvCinoList: rvCinoList });
                            //this.applyGarment();
                        });
                    });        
                });    
            });
    }

    setTopGarment = (value) => {
        this.setState({ selectedTopGarment: value });
    }

    setBottomGarment = (value) => {
        this.setState({ selectedBottomGarment: value });
    }

    setGltf = (value) => {
        this.setState({ gltf: value });
    }

    applyGarment = () => {
        if (this.state.currentScanId) {
            let query = "";
            const gltfLoader = new GLTFLoader();

            this.setState({ isGltfLoading: true });
            query += this.state.selectedTopGarment ? ((query ? "&" : "") + "tops=" + this.state.selectedTopGarment) : "";
            query += this.state.selectedBottomGarment ? ((query ? "&" : "") + "bottoms=" + this.state.selectedBottomGarment) : "";
            gltfLoader.setRequestHeader({ "Authorization": "Bearer " + this.token });
            gltfLoader.load(
                appSettings.api_host + "/scans/" + this.state.currentScanId + ".glb?host=" + this.host + "&mode=" + this.mode + (query ? ("&" + query) : ""),
                (gltf) => { this.setGltf(gltf); this.setState({ isGltfLoading: false }); },
                (xhr) => { },
                (error) => { this.setGltf(null); this.setState({ isGltfLoading: false }); }
            );
        }
    }

    render() {
        return (
            <MainGrid templateColumns="1fr 1fr" templateRows="auto 1fr" height={"100vh"} isDisabled={this.isProcessing}>
                <Flex area="1 / 1 / 2 / 3" direction="column" gap="0px">
                    <Grid backgroundColor="black" width="100vw" height="80px" templateColumns="auto 1fr auto" alignItems="center">
                        <Flex column={1} alignItems="center" margin="8px 24px">
                            <Image src="images/shimadenim-logo.svg" />
                        </Flex>
                        <Flex column={3} alignItems="center" margin="8px">
                            <SelectField labelHidden size="small" width="300px" backgroundColor="white" variation="default" onChange={(e) => this.setScanId(e.target.value)}>
                                {
                                    Object.keys(this.state.scans).map(scanId => (<option key={scanId} value={scanId}>{this.state.scans[scanId]}</option>))
                                }
                            </SelectField>
                            <Menu variation="primary" menuAlign="end" color="white" backgroundColor="white">
                                <MenuItem backgroundColor="white" onClick={() => this.signOut()}>
                                    <Flex alignItems="center">
                                        <FiLogOut />
                                        <Text>Logout</Text>
                                    </Flex>
                                </MenuItem>
                            </Menu>
                        </Flex>
                    </Grid>
                </Flex>
                {/* <Flex area="2 / 1 / 3 / 2">
                    <VirtualClothingView gltf={this.state.gltf} />
                </Flex> */}
                <Flex area="2 / 1 / 3 / 3" direction="column" gap="4px" margin="4px">
                    <GarmentView>
                        <Tabs defaultIndex={1}>
                            <TabItem title="TOPS" isDisabled />
                            <TabItem title="ロゴ T-SHIRTS">
                                <Grid columnGap="0.5rem" rowGap="0.5rem" templateColumns="auto 1fr" templateRows="1fr 2fr">
                                    <Link rowSpan={2} margin="8px" href="https://shimadenim.com/product/logo-t-shirts-%e3%80%90mens%e3%80%91-%e8%a4%87%e8%a3%bd/" isExternal={true}>
                                        <Image width="225px" height="300px" objectFit="contain" objectPosition="50% 50%" src="images/rv_t-shirt.jpg" />
                                    </Link>
                                    <Text column="2" row="1" fontSize="1em" width={"95%"} textAlign={"left"} margin={"8px"}>
                                        着心地の良さの重視した綿100%Tシャツを採用しフロントにSHIMA DENIM WORKSのブランドロゴをプリントしたSHOPオリジナルTシャツです。
                                    </Text>
                                    <Collection column="2" row="2" type="grid" margin="8px" items={this.state.rvTShirtList} gap="1rem" templateColumns="1fr 1fr 1fr 1fr" autoFlow="row">
                                        {(item, index) => (
                                            <Button key={item.key} backgroundColor={this.state.selectedTopGarment === item.key ? "#FFFFD0" : "#FFFFFF"} grow="1" width="100px" onClick={() => this.setTopGarment(item.key)} >
                                                <Grid templateColumns="1fr 2fr 1fr" alignItems="center">
                                                    {item.recommended ? <IconStar column="1" color="#FFD000" /> : null}
                                                    <Text column="2" textAlign="center">{item.label}</Text>
                                                </Grid>
                                            </Button>
                                        )}
                                    </Collection>
                                </Grid>
                            </TabItem>
                            <TabItem title="琉球かりゆしウェア">
                                <Grid columnGap="0.5rem" rowGap="0.5rem" templateColumns="auto 1fr" templateRows="1fr 2fr">
                                    <Link rowSpan={2} margin="8px" href="https://shimadenim.com/product/%e7%90%89%e7%90%83%e7%b4%85%e5%9e%8b%e3%81%8b%e3%82%8a%e3%82%86%e3%81%97%e3%82%a6%e3%82%a7%e3%82%a2%ef%bc%88%e7%ab%b9%e6%a8%a1%e6%a7%98%e3%81%ab%e3%82%a4%e3%82%b8%e3%83%a5%e3%81%8c%e8%ba%8d%e3%82%8b/" isExternal={true}>
                                        <Image width="225px" height="300px" objectFit="contain" objectPosition="50% 50%" src="images/rv_kariyushi-wear.jpg" />
                                    </Link>
                                    <Text column="2" row="1" fontSize="1em" width={"95%"} textAlign={"left"} margin={"8px"}>
                                        沖縄の伝統的工芸品、琉球びんがたのモチーフを使用したかりゆしウェアです。
                                    </Text>
                                    <Collection column="2" row="2" type="grid" margin="8px" items={this.state.rvKariyushiWearList} gap="1rem" templateColumns="1fr 1fr 1fr 1fr" autoFlow="row">
                                        {(item, index) => (
                                            <Button key={item.key} backgroundColor={this.state.selectedTopGarment === item.key ? "#FFFFD0" : "#FFFFFF"} grow="1" width="100px" onClick={() => this.setTopGarment(item.key)} >
                                                <Grid templateColumns="1fr 2fr 1fr" alignItems="center">
                                                    {item.recommended ? <IconStar column="1" color="#FFD000" /> : null}
                                                    <Text column="2" textAlign="center">{item.label}</Text>
                                                </Grid>
                                            </Button>
                                        )}
                                    </Collection>
                                </Grid>
                            </TabItem>
                        </Tabs>
                    </GarmentView>
                    <GarmentView>
                        <Tabs defaultIndex={1}>
                            <TabItem title="BOTTOMS" isDisabled />
                            <TabItem title="ジーンズ【ST】">
                                <Grid columnGap="0.5rem" rowGap="0.5rem" templateColumns="auto 1fr" templateRows="1fr 2fr">
                                    <Link rowSpan={2} margin="8px" href="https://shimadenim.com/product/%e3%82%b8%e3%83%bc%e3%83%b3%e3%82%ba%e3%80%90st%e3%80%91/" isExternal={true}>
                                        <Image width="225px" height="300px" objectFit="contain" objectPosition="50% 50%" src="images/rv_n-shima-1.jpg" />
                                    </Link>
                                    <Text column="2" row="1" fontSize="1em" width={"95%"} textAlign={"left"} margin={"8px"}>
                                        SHIMA DENIMを使ったオリジナルのジーンズ。「綿」と「和紙」からできたこのジーンズは軽さと、さらさらとした独特の肌触りが特徴的な逸品です。オーソドックスなストレートタイプ。
                                    </Text>
                                    <Collection column="2" row="2" type="grid" margin="8px" items={this.state.rvNShima1List} gap="1rem" templateColumns="1fr 1fr 1fr 1fr" autoFlow="row">
                                        {(item, index) => (
                                            <Button key={item.key} backgroundColor={this.state.selectedBottomGarment === item.key ? "#FFFFD0" : "#FFFFFF"} grow="1" width="100px" onClick={() => this.setBottomGarment(item.key)} >
                                                <Grid templateColumns="1fr 2fr 1fr" alignItems="center">
                                                    {item.recommended ? <IconStar column="1" color="#FFD000" /> : null}
                                                    <Text column="2" textAlign="center">{item.label}</Text>
                                                </Grid>
                                            </Button>
                                        )}
                                    </Collection>
                                </Grid>
                            </TabItem>
                            <TabItem title="チノデニム">
                                <Grid columnGap="0.5rem" rowGap="0.5rem" templateColumns="auto 1fr" templateRows="1fr 2fr">
                                    <Link rowSpan={2} margin="8px" href="https://shimadenim.com/product/%e3%82%b8%e3%83%bc%e3%83%b3%e3%82%ba%e3%80%90st%e3%80%91/" isExternal={true}>
                                        <Image width="225px" height="300px" objectFit="contain" objectPosition="50% 50%" src="images/rv_cino.jpg" />
                                    </Link>
                                    <Text column="2" row="1" fontSize="1em" width={"95%"} textAlign={"left"} margin={"8px"}>
                                        SHIMA DENIMのチノ生地を使ったチノデニム。ジーンズ（ST・TA）よりも薄い生地を使用しているため、さらに軽い仕上がり。小さめのサイズも展開しており、女性にもおすすめ。
                                    </Text>
                                    <Collection column="2" row="2" type="grid" margin="8px" items={this.state.rvCinoList} gap="1rem" templateColumns="1fr 1fr 1fr 1fr" autoFlow="row">
                                        {(item, index) => (
                                            <Button key={item.key} backgroundColor={this.state.selectedBottomGarment === item.key ? "#FFFFD0" : "#FFFFFF"} grow="1" width="100px" onClick={() => this.setBottomGarment(item.key)} >
                                                <Grid templateColumns="1fr 2fr 1fr" alignItems="center">
                                                    {item.recommended ? <IconStar column="1" color="#FFD000" /> : null}
                                                    <Text column="2" textAlign="center">{item.label}</Text>
                                                </Grid>
                                            </Button>
                                        )}
                                    </Collection>
                                </Grid>
                            </TabItem>
                        </Tabs>
                    </GarmentView>
                    {/* <Button variation="primary" isDisabled={!this.state.selectedTopGarment && !this.state.selectedBottomGarment} onClick={() => this.applyGarment()} ariaLabel="" margin={"4px"}>
                        Try!
                    </Button> */}
                </Flex>
                {this.state.isProcessing || this.state.isGltfLoading ? <View area="1 / 1 / 3 / 3" backgroundColor="black" width="100%" height="100%" opacity="20%" /> : null}
                {this.state.isProcessing || this.state.isGltfLoading ? <Loader area="1 / 1 / 3 / 3" marginLeft="auto" marginRight="auto" marginTop="auto" marginBottom="auto" width="20vw" height="20vh" /> : null}
            </MainGrid>
        );
    }
}

export default App;
