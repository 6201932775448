/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://wiflqoy3urcgxdx25y54u3xznm.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fsy26og25jewvjsznlhxim7jh4",
    "aws_content_delivery_bucket": "irtuallothingpp-20220520123537-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "http://irtuallothingpp-20220520123537-hostingbucket-staging.s3-website-ap-northeast-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "ap-northeast-1:202f5574-c259-47e1-9e9a-228fe968050c",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_G4oNItWTH",
    "aws_user_pools_web_client_id": "1ljdmeggv9r882i4ns8940set5",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "GENDER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
