
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import React from "react";
import './VirtualClothingView.css';

class VirtualClothingView extends React.Component {

    constructor(props) {
      console.log("VirtualClothingView construct");
  
      super(props);

      this.mountRef = React.createRef();
      this.renderer = null;
      this.controls = null;
      this.camera = null;
      this.scene = null;
      this.gltf = null;
    }

    setupScene = () => {
        this.camera.position.set(0, 1.5, 4);
        this.controls.target.set(0, 1.0, 0);
    
        // レンダラー：シャドウを有効にする
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    
        const light = new THREE.AmbientLight(0xFFFFFF, 0.5);
        this.scene.add(light);
    
        const directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight1.castShadow = true;
        directionalLight1.shadow.mapSize.set(256, 256);
        directionalLight1.position.set(5, 3, 5);
        this.scene.add(directionalLight1);
    
        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight2.castShadow = true;
        directionalLight2.shadow.mapSize.set(256, 256);
        directionalLight2.position.set(-5, 3, 5);
        this.scene.add(directionalLight2);
    
        const directionalLight3 = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight3.castShadow = true;
        directionalLight3.shadow.mapSize.set(256, 256);
        directionalLight3.position.set(5, 3, -5);
        this.scene.add(directionalLight3);
    
        const directionalLight4 = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight4.position.set(-5, 3, -5);
        directionalLight4.castShadow = true;
        directionalLight4.shadow.mapSize.set(256, 256);
        this.scene.add(directionalLight4);
    
        const meshDisk = new THREE.Mesh(
            new THREE.CylinderGeometry(0.5, 0.5, 0.01, 32),
            new THREE.MeshStandardMaterial({ color: 0xC0C0C0 }));
        meshDisk.position.set(0, 0, 0)
        this.scene.add(meshDisk);
    
        const geometry = new THREE.PlaneGeometry(20, 20);
        geometry.rotateX(- Math.PI / 2);
    
        const material = new THREE.ShadowMaterial({ opacity: .2, color: 0xA0A0A0 });
    
        const plane = new THREE.Mesh(geometry, material);
        plane.position.y = 0.01;
        plane.receiveShadow = true;
        this.scene.add(plane);
    }

    setupSubject = () => {
        function castShadowRecursive(object) {
            if (object instanceof THREE.Mesh)
                object.castShadow = true;
            for (var i = 0; i < object.children.length; ++i)
                castShadowRecursive(object.children[i]);
        }
    
        if(this.gltf)
            castShadowRecursive(this.gltf.scene);
    }
    
    renderScene = () => {
        this.renderer.render(this.scene, this.camera);
    }
    
    animate = () => {
        if(this.renderer) {
            requestAnimationFrame(this.animate);
            this.controls.update();
            this.renderScene();
        }
    };

    componentDidMount() {
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(50, 1, 0.1, 1000);
        this.renderer = new THREE.WebGLRenderer({ outputEncoding: THREE.sRGBEncoding, toneMapping: THREE.ACESFilmicToneMapping, alpha: true });
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.mountRef.current.appendChild(this.renderer.domElement);
        this.resizeObserver = new ResizeObserver((event) => {
            const width = Math.floor(event[0].contentRect.width);
            const height = Math.floor(event[0].contentRect.height);
    
            // TODO: イベントが複数コールされることに対する対応
            console.log(width, height, event[0]);
            this.renderer.setSize(width, height, false);
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
            this.renderScene();
        });
        this.resizeObserver.observe(this.mountRef.current);
        this.setupScene();
        if(this.gltf) {
            this.scene.add(this.gltf.scene);
            this.setupSubject();
        }
        this.animate();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.gltf != prevProps.gltf) {
            if(prevProps.gltf)
                this.scene.remove(prevProps.gltf.scene);
            if(this.props.gltf) {
                this.scene.add(this.props.gltf.scene);
                this.gltf = this.props.gltf;
                this.setupSubject();
            }
        }
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
        this.mountRef.current.removeChild(this.renderer.domElement);
        this.renderer = null;
        this.controls = null;
        this.camera = null;
        this.scene = null;
    }

    render() {
        return <div ref={this.mountRef} className="VirtualClothingView" />;
    }
}

export default VirtualClothingView;