
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import awsconfig from './aws-exports';
import appSettings from './app-exports';

awsconfig.aws_user_pools_id = appSettings.aws_user_pools_id;
awsconfig.aws_user_pools_web_client_id = appSettings.aws_user_pools_web_client_id;
awsconfig.aws_cognito_identity_pool_id = appSettings.aws_cognito_identity_pool_id;
Amplify.configure(awsconfig);

ReactDOM.render(
  <React.StrictMode>
    <AmplifyProvider>
      <Authenticator hideSignUp={true} height="100vh">
        {({ signOut, user }) => (
          <App signOut={signOut} user={user} />
        )}
      </Authenticator>
    </AmplifyProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
