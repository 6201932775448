
let appSettings = {};
const params = new URLSearchParams(window.location.search);

// 開発用に設定値をクエリ―パラメータにより切り替え
switch (params.get("host")) {
    case "local":
    case "local_dev":
            appSettings = {
            "host": "dev",
            "mode": params.get("mode") ?? "4",
            "aws_user_pools_id": "ap-northeast-1_QCcVB1p0B",
            "aws_user_pools_web_client_id": "6d1t1p5rfa6ivi7tq8taig8frv",
            "aws_cognito_identity_pool_id": "ap-northeast-1:0035503c-0d61-4bbc-8c8f-78b86e03d567",

            "api_host": "http://127.0.0.1:8000",
        };
        break;

    case "dev":
        appSettings = {
            "host": "dev",
            "mode": params.get("mode") ?? "4",
            "aws_user_pools_id": "ap-northeast-1_QCcVB1p0B",
            "aws_user_pools_web_client_id": "6d1t1p5rfa6ivi7tq8taig8frv",
            "aws_cognito_identity_pool_id": "ap-northeast-1:0035503c-0d61-4bbc-8c8f-78b86e03d567",

            "api_host": "https://vca-engine.metry.style"
        };
        break;

    case "local_prod":
        appSettings = {
            "host": "prod",
            "mode": params.get("mode") ?? "4",
            "aws_user_pools_id": "ap-northeast-1_z7TxLXLSi",
            "aws_user_pools_web_client_id": "dh8lmjrhfgijms99p7eitrbgv",
            "aws_cognito_identity_pool_id": "ap-northeast-1:94eaa827-172b-438c-b614-269aa90f1d9d",

            "api_host": "http://127.0.0.1:8000"
        };
        break;

    case "prod":
    default:
        appSettings = {
            "host": "prod",
            "mode": params.get("mode") ?? "4",
            "aws_user_pools_id": "ap-northeast-1_z7TxLXLSi",
            "aws_user_pools_web_client_id": "dh8lmjrhfgijms99p7eitrbgv",
            "aws_cognito_identity_pool_id": "ap-northeast-1:94eaa827-172b-438c-b614-269aa90f1d9d",

            "api_host": "https://vca-engine.metry.style"
        };
        break;
}

export default appSettings;
